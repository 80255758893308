import '../resources/apty-template.png'
import styles from '../modules/body.module.css'
import Navigation from './navigation';
import Footer from './footer';
import Section from './section'
import Header from './header'
import { Container } from '@mui/material';
import TailwindHeroSection from './tailwind-hero-section';

function Main() {
  return (
    <div className={styles.background}>
      <Header />
      <TailwindHeroSection />
      <Navigation />
      <Container >
        <Section />
      </Container>
      <Footer />
    </div>
  );
}
export default Main;

