

function Footer() {

  return (
    <footer role="contentinfo">
      <p>link to <a href="https://www.apty.io/">Apty</a> website.</p>
    </footer>
  );
}

export default Footer;

