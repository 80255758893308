
import EmbeddedSection from "./embedded-section";
import FormsSection from "./forms-section";
import TextSection from "./text-section";


function Section() {

  return (
    <>
      <TextSection />
      <EmbeddedSection />
      <FormsSection />
    </>
  );
}

export default Section;
