import styles from '../modules/embedd.module.css'
import CustomStyles from '../modules/section.module.css'

function EmbeddedSection() {

  return (
    <section id={styles.embedded} className={CustomStyles.article_background}>

      <header><h1>Embedded content</h1></header>
      <article id="embedded__images" className="Apty_EmbeddedImage" apty-name="emb_image">
        <header><h2>Images</h2></header>
        <div>
          <h3>No <code>&lt;figure&gt;</code> element</h3>
          <p><img src="http://placekitten.com/480/480" alt="Image alt text"></img></p>
          <h3>Wrapped in a <code>&lt;figure&gt;</code> element, no <code>&lt;figcaption&gt;</code></h3>
          <figure><img src="http://placekitten.com/420/420" alt="Image alt text"></img></figure>
          <h3>Wrapped in a <code>&lt;figure&gt;</code> element, with a <code>&lt;figcaption&gt;</code></h3>
          <figure>
            <img src="http://placekitten.com/420/420" alt="Image alt text"></img>
            <figcaption>Here is a caption for this image.</figcaption>
          </figure>
        </div>
        <footer><p><a href="#top">[Top]</a></p></footer>
      </article>
      <article id="embedded__audio" className="Apty_EmbeddedAudio" apty-name="emb_audio">
        <header><h2>Audio</h2></header>
        <div><audio controls={true}>audio</audio></div>
        <footer><p><a href="#top">[Top]</a></p></footer>
      </article>
      <article id="embedded__video" className="Apty_EmbeddedVideo" apty-name="emb_video">
        <header><h2>Video</h2></header>
        <div><video controls={true}>video</video></div>
        <footer><p><a href="#top">[Top]</a></p></footer>
      </article>
      <article id="embedded__canvas" className="Apty_EmbeddedCanvas" apty-name="emb_canvas">
        <header><h2>Canvas</h2></header>
        <div><canvas>canvas</canvas></div>
        <footer><p><a href="#top">[Top]</a></p></footer>
      </article>
      <article id="embedded__meter" className="Apty_EmbeddedMeter" apty-name="emb_meter">
        <header><h2>Meter</h2></header>
        <div><meter value="2" min="0" max="10">2 out of 10</meter></div>
        <footer><p><a href="#top">[Top]</a></p></footer>
      </article>
      <article id="embedded__progress" className="Apty_EmbeddedProgress" apty-name="emb_progress">
        <header><h2>Progress</h2></header>
        <div><progress>progress</progress></div>
        <footer><p><a href="#top">[Top]</a></p></footer>
      </article>
      <article id="embedded__svg" className="Apty_EmbeddedSVG" apty-name="emb_svg">
        <header><h2>Inline SVG</h2></header>
        <div><svg width="100px" height="100px"><circle cx="100" cy="100" r="100" fill="#1fa3ec"></circle></svg></div>
        <footer><p><a href="#top">[Top]</a></p></footer>
      </article>

      <article id="embedded_iframe" className="Apty_EmbeddedIframe" apty-name="emb_iframe">
        <header><h2>IFrame</h2></header>
        <div><iframe src="./pages/details.html" height="600" width="600" id="main-page-iframe"></iframe></div>
        <footer><p><a href="#top">[Top]</a></p></footer>
      </article>
    </section>
  );
}

export default EmbeddedSection;
