import { Link } from 'react-router-dom'
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowDropDownTwoToneIcon from '@mui/icons-material/ArrowDropDownTwoTone';
import Drawer from '@mui/material/Drawer';
import styles from '../modules/header.module.css'
import List from '@mui/material/List';
import ListItems from '@mui/material/ListItem';
import Banner from './banner';


function Header() {

  const [popupVisible, setPopupVisible] = React.useState(false);

  function openPopup() {
    setPopupVisible(!popupVisible);
  }


  const [state, setState] = React.useState(false)

  const toggleDrawer = (open: boolean) => () => { setState(open) }

  const list = () => (
    <div >
      <List >
        <ListItems >
          <Link to={"/DynamicSelectors"} target="_blank" >Dynamic Selectors</Link>
        </ListItems>
        <ListItems >
          <Link to={"/MultiLevelIframe"} target="_blank" >Multi-LevelIframe</Link>
        </ListItems>
        <ListItems >
          <Link to={"/Popover"} target="_blank" >Pop Over Iframe</Link>
        </ListItems>
      </List>

    </div>)

  return (
    <div>

      <Box sx={{ flexGrow: 1 }} >
        <AppBar position="relative" sx={{ backgroundColor: 'black' }}>
          <Toolbar sx={{display: 'flex', justifyContent: 'justify-content'}}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
            <img src='./apty-logo-original.png' />

            <Typography variant="h4" component="div">
              <p onClick={openPopup} className={styles.head}>Monster App <ArrowDropDownTwoToneIcon className={styles.arrow} />
                <Drawer
                  onClick={toggleDrawer(false)}
                  anchor='left'
                  open={state}
                  onClose={toggleDrawer(false)}
                ><Box sx={{ flexGrow: 1, width: 200, backgroundColor: '#FDA172' }}>
                    {list()}
                  </Box>
                </Drawer>

                {
                  popupVisible &&
                  <List className={styles.menu} >
                    <ListItems >
                      <Link to={"/DynamicSelectors"} target="_blank" className={styles.menuitems}>Dynamic Selectors</Link>
                    </ListItems>
                    <ListItems >
                      <Link to={"/MultiLevelIframe"} target="_blank" className={styles.menuitems}>MultiLevelIframe</Link>
                    </ListItems>
                    <ListItems >
                      <Link to={"/Popover"} target="_blank" className={styles.menuitems}>Pop Over Iframe</Link>
                    </ListItems>
                  </List>
                }
              </p>
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>

      <Drawer
        onClick={toggleDrawer(false)}
        anchor='left'
        open={state}
        onClose={toggleDrawer(false)}
      ><Box sx={{ flexGrow: 1, width: 200, backgroundColor: '#FDA172' }}>
          {list()}
        </Box>
      </Drawer>


      <p className={styles.header}>This is a test page filled with common HTML elements to be used to provide visual feedback whilst building CSS systems and frameworks.</p>
      {
        popupVisible &&
        <List className={styles.menu} >
          <ListItems >
            <Link to={"/DynamicSelectors"} target="_blank" className={styles.menuitems}>Dynamic Selectors</Link>
          </ListItems>
          <ListItems >
            <Link to={"/MultiLevelIframe"} target="_blank" className={styles.menuitems}>MultiLevelIframe</Link>
          </ListItems>
          <ListItems >
            <Link to={"/Popover"} target="_blank" className={styles.menuitems}>Pop Over Iframe</Link>
          </ListItems>

        </List>
      }

      <Banner />
    </div>

  );
}
export default Header;
 
