import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup } from '@mui/material';

import CustomStyles from '../modules/section.module.css'

import '../modules/forms.module.css'

function FormsSection() {

  return (
    <section id="forms" className={CustomStyles.article_background}>
      <header><h1>Form elements</h1></header>
      <form>
        <fieldset id="forms_input" className="Apty_FormElements" >
          <legend>Input fields</legend>
          <p>
            <label htmlFor="input_text">Text Input</label>
            <input id="input_text" type="text" placeholder="Text Input" />
          </p>
          <p>
            <label htmlFor="input_password">Password</label>
            <input id="input_password" type="password" placeholder="Type your Password" />
          </p>
          <p>
            <label htmlFor="input_webaddress">Web Address</label>
            <input id="input_webaddress" type="url" placeholder="http://yoursite.com" />
          </p>
          <p>
            <label htmlFor="input_emailaddress">Email Address</label>
            <input id="input_emailaddress" type="email" placeholder="name@email.com" />
          </p>
          <p>
            <label htmlFor="input_phone">Phone Number</label>
            <input id="input_phone" type="tel" placeholder="(999) 999-9999" />
          </p>
          <p>
            <label htmlFor="input_search">Search</label>
            <input id="input_search" type="search" placeholder="Enter Search Term" />
          </p>
          <p>
            <label htmlFor="input_text2">Number Input</label>
            <input id="input_text2" type="number" placeholder="Enter a Number" />
          </p>
          <p>
            <label htmlFor="input_text3" className="error">Error</label>
            <input id="input_text3" className="is-error" type="text" placeholder="Text Input" />
          </p>
          <p>
            <label htmlFor="input_text4" className="valid">Valid</label>
            <input id="input_text4" className="is-valid" type="text" placeholder="Text Input" />
          </p>
        </fieldset>
        <p><a href="#top">[Top]</a></p>
        <fieldset id="forms_select">
          <legend>Select menus</legend>
          <p>
            <label htmlFor="select">Select</label>
            <select id="select">
              <optgroup label="Option Group">
                <option>Option One</option>
                <option>Option Two</option>
                <option>Option Three</option>
              </optgroup>
            </select>
          </p>
        </fieldset>
        <p><a href="#top">[Top]</a></p>
        <fieldset id="forms_checkbox">
          <legend>Checkboxes</legend>


          <FormGroup>
            <FormControlLabel control={<Checkbox defaultChecked />} label="Default checked" />
            <FormControlLabel control={<Checkbox />} label="Normal Checkbox" />
            <FormControlLabel disabled control={<Checkbox />} label="Disabled Checkbox" />


          </FormGroup>


        </fieldset>
        <p><a href="#top">[Top]</a></p>

        <fieldset id="forms_radio">
          <legend>Radio buttons</legend>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
            >
              <FormControlLabel value="female" control={<Radio />} label="Female" />
              <FormControlLabel value="male" control={<Radio />} label="Male" />
              <FormControlLabel value="other" control={<Radio />} label="Other" />
            </RadioGroup>
          </FormControl>

        </fieldset>

        <p><a href="#top">[Top]</a></p>

        <fieldset id="forms_textareas">
          <legend>Textareas</legend>
          <p>
            <label htmlFor="textarea">Textarea</label>
            <textarea id="textarea" rows={8} cols={48} placeholder="Enter your message here"></textarea>
          </p>
        </fieldset>
        <fieldset id="forms_html5">
          <legend>HTML5 inputs</legend>
          <p>
            <label htmlFor="ic">Color input</label>
            <input type="color" id="ic" value="#000000" />
          </p>
          <p>
            <label htmlFor="in">Number input</label>
            <input type="number" id="in" min={0} max={10} value="5" />
          </p>
          <p>
            <label htmlFor="ir">Range input</label>
            <input type="range" id="ir" value="10" />
          </p>
          <p>
            <label htmlFor="idd">Date input</label>
            <input type="date" id="idd" value="1970-01-01" />
          </p>
          <p>
            <label htmlFor="idm">Month input</label>
            <input type="month" id="idm" value="1970-01" />
          </p>
          <p>
            <label htmlFor="idw">Week input</label>
            <input type="week" id="idw" value="1970-W01" />
          </p>
          <p>
            <label htmlFor="idt">Datetime input</label>
            <input type="datetime" id="idt" value="1970-01-01T00:00:00Z" />
          </p>
          <p>
            <label htmlFor="idtl">Datetime-local input</label>
            <input type="datetime-local" id="idtl" value="1970-01-01T00:00" />
          </p>
        </fieldset>
        <p><a href="#top">[Top]</a></p>
        <fieldset id="forms_action">
          <legend>Action buttons</legend>
          <p>
            <input id="submitBtn" type="submit" value="<input type=submit>" />
            <input id="btn" type="button" value="<input type=button>" />
            <input id="resetBtn" type="reset" value="<input type=reset>" />
            <input type="submit" value="<input disabled>" disabled></input>
          </p>
          <p>
            <button type="submit">&lt;button type=submit&gt;</button>
            <button type="button">&lt;button type=button&gt;</button>
            <button type="reset">&lt;button type=reset&gt;</button>
            <button type="button" disabled>&lt;button disabled&gt;</button>
          </p>
        </fieldset>
        <p><a href="#top">[Top]</a></p>
      </form>
    </section>

  );
}

export default FormsSection;

