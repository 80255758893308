import styles from '../modules/navigation.module.css'

function Navigation() {

  return (
    <nav>
      <section className='text-center mx-8'>
        <div className="container mx-auto flex flex-wrap justify-center">
          <div className="text-slate-900 text-4xl tracking-tight font-extrabold sm:text-5xl">Links to elements</div>
        </div>
        <div className="container mx-auto flex flex-wrap justify-center">
          <div className="lg:w-1/4 md:w-1/2 w-full p-4">
            <div className="p-8 rounded-xl shadow-lg bg-indigo-200">
              <div className="justify-start"><a className={styles.links + ' text-gray-500'} href="#text__headings">Headings</a></div>
              <div className="justify-start"><a className={styles.links + ' text-gray-500'} href="#text__paragraphs">Paragraphs</a></div>
              <div className="justify-start"><a className={styles.links + ' text-gray-500'} href="#text__blockquotes">Blockquotes</a></div>
              <div className="justify-start"><a className={styles.links + ' text-gray-500'} href="#text__lists">Lists</a></div>
              <div className="justify-start"><a className={styles.links + ' text-gray-500'} href="#text__hr">Horizontal rules</a></div>
              <div className="justify-start"><a className={styles.links + ' text-gray-500'} href="#text__tables">Tabular data</a></div>
              <div className="justify-start"><a className={styles.links + ' text-gray-500'} href="#text__code">Code</a></div>
              <div className="justify-start"><a className={styles.links + ' text-gray-500'} href="#text__inline">Inline elements</a></div>
              <div className="justify-start"><a className={styles.links + ' text-gray-500'} href="#text__comments">HTML Comments</a></div>
            </div>
          </div>

          <div className="lg:w-1/4 md:w-1/2 w-full p-4">
            <div className="p-8 rounded-xl shadow-lg bg-indigo-200">
              <div className="justify-start"><a className={styles.links + ' text-gray-500'} href="#forms__input">Input fields</a></div>
              <div className="justify-start"><a className={styles.links + ' text-gray-500'} href="#forms__select">Select menus</a></div>
              <div className="justify-start"><a className={styles.links + ' text-gray-500'} href="#forms__checkbox">Checkboxes</a></div>
              <div className="justify-start"><a className={styles.links + ' text-gray-500'} href="#forms__radio">Radio buttons</a></div>
              <div className="justify-start"><a className={styles.links + ' text-gray-500'} href="#forms__textareas">Textareas</a></div>
              <div className="justify-start"><a className={styles.links + ' text-gray-500'} href="#forms__html5">HTML5 inputs</a></div>
              <div className="justify-start"><a className={styles.links + ' text-gray-500'} href="#forms__action">Action buttons</a></div>
            </div>
          </div>

          <div className="lg:w-1/4 md:w-1/2 w-full p-4">
            <div className="p-8 rounded-xl shadow-lg bg-indigo-200">
              <div className="justify-start"><a className={styles.links + ' text-gray-500'} href="#embedded__images">Images</a></div>
              <div className="justify-start"><a className={styles.links + ' text-gray-500'} href="#embedded__audio">Audio</a></div>
              <div className="justify-start"><a className={styles.links + ' text-gray-500'} href="#embedded__video">Video</a></div>
              <div className="justify-start"><a className={styles.links + ' text-gray-500'} href="#embedded__canvas">Canvas</a></div>
              <div className="justify-start"><a className={styles.links + ' text-gray-500'} href="#embedded__meter">Meter</a></div>
              <div className="justify-start"><a className={styles.links + ' text-gray-500'} href="#embedded__progress">Progress</a></div>
              <div className="justify-start"><a className={styles.links + ' text-gray-500'} href="#embedded__svg">Inline SVG</a></div>
              <div className="justify-start"><a className={styles.links + ' text-gray-500'} href="#embedded__iframe">IFrames</a></div>
            </div>
          </div>
        </div>
      </section>
    </nav>
  );
}

export default Navigation;
