import { useEffect } from "react";


function DynamicSelectors() {
  function onRender() {
    dynamicElement();
    dynamicName();
    dynamicClass()
    dynamicId();
    dynamicNameClass();
    dynamicNameClassTooltip();
  }

  useEffect(() => {
    onRender();
  }, []);

  function randomGenarator() {
    let randGen = Math.random()
    randGen = randGen.toString()
    randGen = randGen.substring(2, 6);
    return randGen
  }

  function dynamicName() {
    let element = document.getElementById('textField1')
    var random = randomGenarator()
    element.setAttribute("name", 'apty-' + random);

  }

  function dynamicId() {
    let collection = document.getElementsByClassName('Last Name')
    var random = randomGenarator()
    collection[0].setAttribute("id", 'aptyId-' + random);

  }

  function dynamicClass() {
    let element = document.getElementById('textField2')
    let random = randomGenarator()
    element.setAttribute("class", 'apty dropdown -' + random);

  }

  function dynamicElement() {
    let element = document.getElementById('dynamicId')
    let random
    setInterval(function () {
      random = randomGenarator()
      element.setAttribute("tooltip", random)
    }, 1000);
  }

  function dynamicNameClass() {
    let element = document.getElementById('textField4')
    var random = randomGenarator()
    element.setAttribute("name", 'apty-' + random);
    random = randomGenarator()
    element.setAttribute("class", 'apty dropdown -' + random);
  }

  function dynamicNameClassTooltip() {
    let element = document.getElementById('textField5')
    var random = randomGenarator()
    element.setAttribute("name", 'apty-' + random);
    random = randomGenarator()
    element.setAttribute("class", 'apty dropdown -' + random);
    random = randomGenarator()
    element.setAttribute("tooltip", 'tooltip -' + random);
  }
  return (
    <>
      <h1 id="dynamicId">Hello this is a dynamic element  </h1>
      <form>
        <fieldset id="forms__input">
          <legend>Enter Details</legend> <br />

          <label>Enter First name</label> <input type="text" id="textField1" name="apty-123" placeholder="dynamic name" /> <br /> <br />

          <label>Enter Second name</label> <input type="text" className="apty dropdown" id="textField2" name="apty-123" placeholder="dynamic class" /> <br /> <br />

          <label>Enter Last name</label>  <input type="text" className="Last Name" id="textField3" placeholder="dynamic id" /> <br /> <br />

          <label>Enter address</label>  <input type="text" id="textField4" className="apty dropdown" name="apty-123" placeholder="dynamic class & name" /><br /> <br />

          <label>Enter email</label>  <input type="email" id="textField5" className="apty dropdown" name="apty-123" tooltip="tooltip" placeholder="dynamic class,name & tooltip" size="25" /><br /> <br />

          <label>Enter phone</label>  <input type="text" /><br /> <br />

          <label>Enter employee id</label>  <input type="text" /><br /> <br />

          <input type="submit" value='Submit' />  <br /> <br />

        </fieldset>
      </form>

    </>
  );
}

export default DynamicSelectors;

