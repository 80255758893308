
function MultiLevelIframe() {

  return (
    <>
      <h1 >Multi-Level Iframes</h1>
      <iframe id="iframe1" src="./pages/details.html" height="600" width="600" /><br /><br /><br /><br />
      <iframe id="iframe2" src="./pages/singleLevelframe.html" height="800" width="600" /><br /><br /><br /><br /><br />
      <iframe id="iframe4" src="./pages/doubleLevelIframe.html" height="1000" width="600" /><br /><br />
    </>
  );
}

export default MultiLevelIframe;
