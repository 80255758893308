import styles from '../modules/textsection.module.css'
import CustomStyles from '../modules/section.module.css'
function TextSection() {

  return (
    <section id="text">
      <header><span id={styles.textheader}>Text</span></header>
      <article id="text__headings" className={styles.headingArticle}>
        <header>
          <h1>Headings</h1>
        </header>
        <div>
          <h1>Heading 1</h1>
          <h2>Heading 2</h2>
          <h3>Heading 3</h3>
          <h4>Heading 4</h4>
          <h5>Heading 5</h5>
          <h6>Heading 6</h6>
        </div>
        <footer><p><a href="#top">[Top]</a></p></footer>
      </article>
      <article id={styles.text_paragraphs} className={CustomStyles.article_background}>
        <header><h1>Paragraphs</h1></header>
        <div>
          <p>A paragraph (from the Greek paragraphos, `&quot;`to write beside`&quot;` or `&quot;`written beside`&quot;`) is a self-contained unit of a discourse in writing dealing with a particular point or idea. A paragraph consists of one or more sentences. Though not required by the syntax of any language, paragraphs are usually an expected part of formal writing, used to organize longer prose.</p>
        </div>
        <footer><p><a href="#top">[Top]</a></p></footer>
      </article>
      <article id={styles.text_blockquotes} className={CustomStyles.article_background}>
        <header><h1>Blockquotes</h1></header>
        <div>
          <blockquote>
            <p>A block quotation (also known as a long quotation or extract) is a quotation in a written document, that is set off from the main text as a paragraph, or block of text.</p>
            <p>It is typically distinguished visually using indentation and a different typeface or smaller size quotation. It may or may not include a citation, usually placed at the bottom.</p>
            <cite><a href="#!">Said no one, ever.</a></cite>
          </blockquote>
        </div>
        <footer><p><a href="#top">[Top]</a></p></footer>
      </article>
      <article id={styles.text_lists} className={CustomStyles.article_background}>
        <header><h1>Lists</h1></header>
        <div>
          <h3>Definition list</h3>
          <dl>
            <dt>Definition List Title</dt>
            <dd>This is a definition list division.</dd>
          </dl>
          <h3>Ordered List</h3>
          <ol>
            <li>List Item 1</li>
            <li>List Item 2</li>
            <li>List Item 3</li>
          </ol>
          <h3>Unordered List</h3>
          <ul>
            <li>List Item 1</li>
            <li>List Item 2</li>
            <li>List Item 3</li>
          </ul>
        </div>
        <footer><p><a href="#top">[Top]</a></p></footer>
      </article>
      <article id={styles.text_hr}>
        <header><h1>Horizontal rules</h1> </header>
        <div>
          <hr></hr>
        </div>
        <footer><p><a href="#top">[Top]</a></p></footer>
      </article>
      <article className={CustomStyles.article_background}>
        <header id={styles.tableheader}><h1>Tabular data</h1></header>
        <table id={styles.text_table}>
          <caption>Table Caption</caption>
          <thead>
            <tr >
              <th>Table Heading 1</th>
              <th>Table Heading 2</th>
              <th>Table Heading 3</th>
              <th>Table Heading 4</th>
              <th>Table Heading 5</th>
            </tr>
          </thead>
          <tfoot id={styles.table_footers}>
            <tr>
              <th>Table Footer 1</th>
              <th>Table Footer 2</th>
              <th>Table Footer 3</th>
              <th>Table Footer 4</th>
              <th>Table Footer 5</th>
            </tr>
          </tfoot>
          <tbody>
            <tr>
              <td>Table Cell 1</td>
              <td>Table Cell 2</td>
              <td>Table Cell 3</td>
              <td>Table Cell 4</td>
              <td>Table Cell 5</td>
            </tr>
            <tr>
              <td>Table Cell 1</td>
              <td>Table Cell 2</td>
              <td>Table Cell 3</td>
              <td>Table Cell 4</td>
              <td>Table Cell 5</td>
            </tr>
            <tr>
              <td>Table Cell 1</td>
              <td>Table Cell 2</td>
              <td>Table Cell 3</td>
              <td>Table Cell 4</td>
              <td>Table Cell 5</td>
            </tr>
            <tr>
              <td>Table Cell 1</td>
              <td>Table Cell 2</td>
              <td>Table Cell 3</td>
              <td>Table Cell 4</td>
              <td>Table Cell 5</td>
            </tr>
          </tbody>
        </table>
        <footer className={styles.articlefooters}><p><a href="#top">[Top]</a></p></footer>
      </article>

      <article id="text_code" >
        <header><h1>Code</h1></header>
        <div>
          <p><strong>Keyboard input:</strong> <kbd>Cmd</kbd></p>
          <p><strong>Inline code:</strong> <code>&lt;div&gt;code&lt;/div&gt;</code></p>
          <p><strong>Sample output:</strong> <samp>This is sample output from a computer program.</samp></p>
          <div id={CustomStyles.preformat_text} className={CustomStyles.article_background}>
          <h2>Pre-formatted text</h2>
          <pre>{`P R E F O R M A T T E D T E X T 
  ! " # $ % `}&amp; {` ' ( ) * + , - . /  
  0 1 2 3 4 5 6 7 8 9 : ;`} &lt; = &gt; {`? 
  @ A B C D E F G H I J K L M N O  
  P Q R S T U V W X Y Z [ \\ ] ^ _ 
  `}` {`a b c d e f g h i j k l m n o  
  p q r s t u v w x y z`} {"{"} | {"}"} ~
          </pre>
          <footer className={styles.articlefooters}><p><a href="#top">[Top]</a></p></footer>
          </div>
        </div>
        
      </article>
      <article id="text_inline">
        <header><h1>Inline elements</h1></header>
        <div>
          <p><a href="#!">This is a text link</a>.</p>
          <p><strong>Strong is used to indicate strong importance.</strong></p>
          <p><em>This text has added emphasis.</em></p>
          <p>The <b>b element</b> is stylistically different text from normal text, without any special importance.</p>
          <p>The <i>i element</i> is text that is offset from the normal text.</p>
          <p>The <u>u element</u> is text with an unarticulated, though explicitly rendered, non-textual annotation.</p>
          <p><del>This text is deleted</del> and <ins>This text is inserted</ins>.</p>
          <p><s>This text has a strikethrough</s>.</p>
          <p>Superscript<sup>®</sup>.</p>
          <p>Subscript for things like H<sub>2</sub>O.</p>
          <p><small>This small text is small for for fine print, etc.</small></p>
          <p>Abbreviation: <abbr title="HyperText Markup Language">HTML</abbr></p>
          <p><q cite="https://developer.mozilla.org/en-US/docs/HTML/Element/q">This text is a short inline quotation.</q></p>
          <p><cite>This is a citation.</cite></p>
          <p>The <dfn>dfn element</dfn> indicates a definition.</p>
          <p>The <mark>mark element</mark> indicates a highlight.</p>
          <p>The <var>variable element</var>, such as <var>x</var> = <var>y</var>.</p>
          <p>The time element: <time>2 weeks ago</time></p>
        </div>
        <footer><p><a href="#top">[Top]</a></p></footer>
      </article>
      <article id="text_comments" className={CustomStyles.article_background}>
        <header><h1>HTML Comments</h1></header>
        <div>
          <p>There is comment here: {/*This comment should not be displayed*/}</p>
          <p>There is a comment spanning multiple tags and lines below here.</p>
          {/*<!--<p><a href="#!">This is a text link. But it should not be displayed in a comment</a>.</p>
          <p><strong>Strong is used to indicate strong importance. But, it should not be displayed in a comment</strong></p>
          <p><em>This text has added emphasis. But, it should not be displayed in a comment</em></p>*/}
        </div>
        <footer><p><a href="#top">[Top]</a></p></footer>
      </article>
    </section>
  );
}

export default TextSection;

